const dev = {
  s3: {
    REGION: "us-east-1",
    BUCKET: "notes-app-boilerplate-api-dev-attachmentsbucket-14tpbwcy17nphE"
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://5z3l5e3dj4.execute-api.us-east-1.amazonaws.com/dev"
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_IzczhANsq",
    APP_CLIENT_ID: "7tpd2nqr21kvb20gdms9ddncrf",
    IDENTITY_POOL_ID: "us-east-1:128381ad-1e44-4584-adc7-3dd533b0d1f6"
  },
  STRIPE_KEY: "pk_test_9ded7f0dLp0YKAWlUGMcijGT"
};

const prod = {
  s3: {
    REGION: "us-east-1",
    BUCKET: "notes-app-boilerplate-api-prod-attachmentsbucket-1304c9lgohxj7"
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://93vferfi5g.execute-api.us-east-1.amazonaws.com/prod"
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_mzIp96d7l",
    APP_CLIENT_ID: "1r597u0l9n5r22rsrk168gbpmq",
    IDENTITY_POOL_ID: "us-east-1:94e5c212-bfce-48df-8308-7c5cef7d50c0"
  },
  STRIPE_KEY: "pk_test_9ded7f0dLp0YKAWlUGMcijGT"
};

// Default to dev if not set
const config = process.env.REACT_APP_STAGE === 'prod'
  ? prod
  : dev;

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config
};